import React from 'react';
import '../styles/footer.scss'; 

function Footer(props) {
    return (
        <div class="wd-100">
            <div class="orange"/>
            <div class="footer d-flex flex-center-x">
                <div class="desktop-lg">
                    <div class="grided                                                     ">
                        <div class="grid-left d-flex">
                            <div>
                                 <img src={require('../assets/BRI_White.png')} id="footer-bri" alt="bri logo"/>
                            </div>
                        </div>
                        <div class="grid-right">
                                <p>Conducted by:</p>
                                <img src={require("../assets/Greenwillow@4x.png")} alt="greenwillow logo" id="greenwillowfooter"/>
                                <img src={require('../assets/Starventure@4x.png')} alt="starventure logo"  id="star-venturefooter"/>
                        </div>
                    </div>
                    <hr class="white" />
                    <div class="grided">
                        <div class="grid-left d-flex flex-left">
                            <div>
                                Contact us:
                                <div class="d-flex flex-center-y icon-label-footer">
                                    <img src={require('../assets/Whatsapp.png')}  id="footer-whatsapp" alt="email us" /> 082322882438
                                </div>
                                <div class="d-flex flex-center-y icon-label-footer">
                                    <img src={require('../assets/Email logo@4x.png')}  id="footer-email" alt="email us" /> admin@bri-id.events
                                </div>
                                
                            </div>
                        </div>
                        <div class="grid-right">
                            <div id="nav-footer">
                                <ul>
                                <li class="text"><a href="/">Home</a></li>
                                <li><div class="vl"></div></li>
                                <li class="text"><a href="/program">Program</a></li>
                                <li><div class="vl"></div></li>
                                <li class="text"><a href="/register">Register Now</a></li>
                                <li><div class="vl"></div></li>
                                <li class="text"><a href="/contact">Contact Us</a></li>
                                <li><div class="vl"></div></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="mt-20 fs-10">
                    Copyright © 2023 Belt and Road Initiatives Indonesia - Jakarta 16 January 2024 - All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;