import React from 'react';
import "./styles/home.scss";
import ImageCarousel from './components/ImageCrousel';
import Gallery from './components/Gallery';

const aboutItems = [
    {
        title: 'Our Background',
        img:'rice-field-bali web.png',
        alt: 'Image by nikitabuida',
        text:'Greenwillow Capital Management brings to the table its expertise in finance and investment, steering the strategic direction and providing the financial backbone necessary for large-scale technological implementation in agriculture. PT Starcamp Bisnis Akselerator, with its deep understanding of the Indonesian market and strong local networks, plays a pivotal role in aligning our project with the Belt and Road Initiative (BRI) requirements and ensuring smooth on-ground integration. The China-EU Association Technology & Education Committee bridges the gap between Eastern and Western agricultural practices, offering a global perspective and facilitating international relations.'
    },
    {
        title:'Our Approach',
        img: 'low-angle-shot-airplane-flying-closer-daytime web.png',
        alt: 'image by wirestock',
        text:'We are pioneering a professional smart agricultural management service platform in Indonesia, incorporating deep-tech solutions from Beijing Fortune Technology Pte Ltd. Our approach is multifaceted - enhancing local agricultural production, ensuring the export of high-quality produce, and setting up a robust infrastructure for ongoing development. The establishment of a joint venture company in Singapore as our Regional HQ is a testament to our commitment to expanding our impact beyond Indonesia to Southeast Asia and the Middle East.'
    },
    {
        title:'Our Commitment',
        img:'market-concept-with-vegetables web.png',
        alt: 'Image by https://www.freepik.com/free-photo/market-concept-with-vegetables_15480232.htm#query=local%20market&position=11&from_view=keyword&track=ais&uuid=c2d45b24-5fe7-456b-bc02-77fb7de6293f"',
        text:'Our collaboration is more than just a business venture; it is a commitment to improving food security, promoting sustainable agricultural practices, and fostering economic growth in the regions we serve. We are dedicated to creating an agricultural ecosystem that is not only efficient and productive but also sustainable and responsible, ensuring a better future for generations to come.'
    },
    {
        title:'Join Us on Our Journey',
        img:'panorama-nature-moldova-dniester-village-with-contry-roads-fields-extending-horizon-view-from-drone web.png',
        alt: 'Image by frimufilms',
        text:`As we embark on this ambitious journey, we invite you to join us in making a meaningful impact on the world's agricultural landscape. Together, we can create a future where technology and sustainability go hand in hand in nurturing the earth and its people.`}
]

function HomePage() {
    function AboutSection({ prop }) {
        return (
            <div class="about-section">
                <div class="title-card">
                    <h1>{prop.title}</h1>
                    <img src={require(`./assets/${prop.img}`)} alt={prop.alt} />
                </div>
                <p>{prop.text}</p>
            </div>
        );
    }
    return (
        <div>
            <div class="behind">
                <ImageCarousel />
            </div>
            <div class="d-flex flex-center-x">
                <div class="desktop-md">
                    <div class="intro">
                        <h1>BELT & ROAD INITIATIVE</h1>
                        <h2>JAKARTA JAN 2024</h2>
                        <div class="orange"/>
                        <div class="text-box">
                        <h3>Innovative Collaboration for Agricultural Advancement</h3>
                        <p>
                        At the heart of our initiative is a groundbreaking collaboration between Greenwillow Capital Management Pte Ltd, PT Starcamp Bisnis Akselerator, and the China-EU Association Technology & Education Committee. This partnership is founded on a shared vision to revolutionize agricultural practices in Indonesia and Southeast Asia through state-of-the-art technology and sustainable methods. 
                        </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wd-100 color-grey d-flex flex-center-x" id="abou">
                <div class="desktop-md">
                    {aboutItems.map((a,i) => {
                        return(
                        <AboutSection prop={a} index={`about-${i}`} />);
                    })}
                </div>
            </div>
            <div class="d-flex flex-center-x">
                <div class="desktop-lg">
                    <div class="vision-mission">
                        <div id="vision">
                            <div class="wd-100 d-flex">
                            <h1>Vision</h1></div>
                            <div class="orange mt-10 mb-10"/>
                            <p>Innovating for a Sustainable and Secure Future: Our collaboration is dedicated to revolutionizing agricultural practices in Indonesia and beyond, using international partnerships and advanced technology. We are committed to enhancing food security, setting new standards in sustainable farming, and expanding smart agricultural solutions across Southeast Asia. Our vision is to create a more efficient, sustainable, and productive agricultural ecosystem that ensures quality and compliance in the global market. This initiative aims to benefit communities and economies at both regional and global scales, ensuring reliable access to nutritious food while promoting sustainable growth.</p>
                        </div>
                        <div class="d-flex flex-center-x" id="b">
                            <img src={require('./assets/adult-arm-keeping-lamp-with-plan.jpg')} alt="/adult-arm-keeping-lamp-with-planet_2390945.htm" />
                        </div>
                        <div class="d-flex flex-center-x" id="mission">
                            <img alt="By master1305" src={require('./assets/building-business-success-hands.jpg')} />
                        </div>
                        <div>
                        <div class="wd-100 d-flex" id="d"><h1>Mission</h1></div>
                            <div class="orange mt-10 mb-10"/>
                            <p>Our mission is to harness the power of cutting-edge agricultural technology and international collaboration to transform the agricultural sector in Indonesia and Southeast Asia. We are dedicated to importing and implementing smart, deep-tech agricultural solutions that enhance the efficiency, sustainability, and productivity of farming practices. By fostering strong partnerships and leveraging the expertise of Greenwillow Capital Management, PT Starcamp Bisnis Akselerator (Starventure), and the China-EU Association Technology & Education Committee, we aim to establish a professional smart agricultural management service platform. This platform will not only ensure adherence to international standards but also facilitate the export of high-quality Indonesian agricultural produce to China and other global markets. Our commitment extends to setting up robust infrastructure and partnerships in Indonesia to support these initiatives, and establishing a joint venture company to oversee regional expansion and investment strategies. Our mission encapsulates our dedication to improving food security, fostering sustainable agricultural development, and contributing positively to economic growth and environmental stewardship in the regions we serve.</p>    
                        </div>
                        
                    </div>
                </div>
            </div>
            <Gallery title="BRI Agriculture Picture" start="1" end="12" />
            <Gallery title="BRI Aviation Picture" start="13" end="21" />
            <Gallery title="MC and Performers" start="22" end="27" />
        </div>
    );
}

export default HomePage;