import React, { useState } from 'react';
import "../styles/home.scss";

function PopUpImage({ imageUrl, onClose }) {
    console.log(imageUrl);
    return (
        <div className="popup-container" onClick={onClose}>
            <img src={require(`../assets/gallery/${imageUrl}v.png`)} alt="popup-img" />
        </div>
    );
}

function Gallery(props) {
    const [selectedImage, setSelectedImage] = useState(null);

    const startInt = parseInt(props.start, 10);
    const endInt = parseInt(props.end, 10);
    const numbers = Array.from({ length: endInt - startInt + 1 }, (_, index) => (startInt + index).toString());

    const openPopup = (image) => {
        setSelectedImage(image);
    };

    const closePopup = () => {
        setSelectedImage(null);
    };

    return (
        <div class="d-flex flex-center-x">
            {selectedImage && (
                <PopUpImage imageUrl={selectedImage} onClose={closePopup} />
            )}
            <div class="desktop-lg">
                <div class="bri-pic">
                    <h1>{props.title}</h1>
                    <div class="orange mt-10 mb-10 width-80"/>
                    <div class="ml-50 mr-50 mt-30 mb-30">
                        <div class="row">
                            {numbers.map((item, key) => (
                                <div class="col-md-4 gallery-img" key={`img-${item}`}>
                                    <img
                                        src={require(`../assets/gallery/${item}v.png`)}
                                        alt={`${item}-gallery`}
                                        key={`image-${item}`}
                                        onClick={() => openPopup(item)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;