import React, { useState } from 'react';
import "../styles/navbar.scss";

const home = [
  { text: 'About Us', route: '/#about' },
  { text: 'Vision', route: '/#vision' },
  { text: 'Mission', route: '/#mission' },
];
const program = [
  { text: 'Topics', route: '/program/#topics' },
  { text: 'Key Note Speakers', route: '/program/#keynotespeakers' },
  { text: 'Speakers', route: '/program/#speakers' },
  { text:`Speaker's CV`, route: '/program/cv'}
];

function NavBar() {
  const [subMenu, setSubMenu] = useState('');

  const handleMouseEnter = (prop) => {
    setSubMenu(prop);
  };

  const handleMouseLeave = () => {
    setSubMenu('');
  };

  function SubMenuComponent({ name }) {
    if (name === '') {
      return <></>;
    } else {
      let arr = name === 'home' ? home : program;
      return (
        <div className="nav-subsection p-absolute" onMouseEnter={() => handleMouseEnter(name)}
        onMouseLeave={handleMouseLeave} >
          <div className="nav-options">
            <ul>
              {arr.map((i, index) => (
                <React.Fragment key={index}>
                  
                  <li className="text"><a href={i.route}>{i.text}</a></li>
                  <li><div className="vl"></div></li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  }

  return (
    <div> 
      <div class="navbar d-flex flex-center">
        <div class="title">
            <img src={require('../assets/BRI_2.1.png')} alt="BRI logo" />
            <div class="vl"></div>
            <img src={require('../assets/Indonesia.png')} alt="indonesia" />
            <h1>BELT & ROAD INITIATIVE</h1>
            <img src={require('../assets/China.png')} alt="china" />
            <img src={require('../assets/BRI_2.1.png')} alt="BRI logo" class="gone" />
        </div>
      </div>
      <div class="p-relative">
        <div className="nav-section">
          <div className="nav-options">
            <ul>
              <li
                className={`text ${subMenu === 'home' ? 'active' : ''}`}
                onMouseEnter={() => handleMouseEnter('home')}
                onMouseLeave={handleMouseLeave}
                id="hometab"
              >
                <a href="/">
                Home</a>
              </li>
              <li><div className="vl"></div></li>
              <li
                className={`text ${subMenu === 'program' ? 'active' : ''}`}
                onMouseEnter={() => handleMouseEnter('program')}
                onMouseLeave={handleMouseLeave}
                id="programtab"
              > <a href="/program">
                Program</a>
              </li>
              <li ><div class="vl"></div></li>
              <li class="text"><a href="/register">Register Now</a></li>
              <li><div class="vl"></div></li>
              <li class="text"><a href="/contact">Contact Us</a></li>
              <li><div class="vl"></div></li>
            </ul>
          </div>
        </div>
        {subMenu !== '' && <SubMenuComponent name={subMenu} />}
      </div>
    </div>
  );
}

export default NavBar;
