import React from 'react';
import './styles/program.scss';

function ProgramPage(props) {
    return (
        <div class="program-page">
            <div class="img">
                <img src={require('./assets/rice-fields-bali2.jpg')} alt="rice field" />
            </div>
            <div class="d-flex flex-center-x">
                <div class="desktop-lg mt-40">
                    <h1>BELT & ROAD INITIATIVE</h1>
                    <div class="orange"/>
                    <div class="icon-labels mb-40">
                        <div class="icon-label pt-15 pb-15">
                            <img src={require('./assets/Clock@4x.png')} alt="time" class="icon"/>
                            <div class="label-text ml-15">
                            January 16, 2024 <br/>08.00 - 13.00 WIB
                            </div>
                        </div>
                        <div class="icon-label pt-20 pb-20">
                            <img src={require('./assets/location@4x.png')} alt="time" class="icon"/>
                            <div class="label-text ml-20">
                            Gran Melia Hotel, Jl. HR. Rasuna Said Block X-O, Kuningan, Jakarta 12950, Indonesia
                            </div>
                        </div>
                    </div>
                    <div class="mb-100">
                        <table>
                            <thead>
                                <tr>
                                    <th>SCHEDULE</th>
                                    <th>RUNDOWN</th>
                                </tr>
                            </thead>
                            <tr>
                                <td>08.00-08.45<br/><br/><br/></td>
                                <td>Registration<br/><br/>Entertainment</td>
                            </tr>
                            <tr>
                                <td>08.45-09.00</td>
                                <td>Opening MC</td>
                            </tr>
                            <tr>
                                <td><br/></td>
                                <td>Challenges and Opportunities in the Belt and Road Initiative (BRI) for Agriculture: Exploring the impact of the BRI on agricultural & aviation development in Indonesia</td>
                            </tr>
                            <tr>
                                <td>09.00-09.10</td>
                                <td><b>Keynote Speech: Kemenlu (tbc)</b></td>
                            </tr>
                            <tr>
                                <td>09.10-09.20</td>
                                <td><b>Keynote Speech: BRI official from China, Beijing (Dr John Xing)</b></td>
                            </tr>
                            <tr>
                                <td>09.20-10.45</td>
                                <td><b>Panel Discussion Agriculture</b></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>The Expert :<br/>
                                1. Lisa Liu (Ms)- General Manager of BEIJING FORTUNE TECHNOLOGY CO. LTD (confirmed)<br/>
                                2. Prof. Dr. Ir. Reni Mayerni, M.P. (Deputi Bidang Pengkajian Strategik Lemhannas RI)<br/>
                                3. Prof (R). Ir. H. Didiek Hadjar Goenadi, M.Sc. Ph.D., IPU., INV<br/>
                                4. Prof Pieter Tangka<br/>
                                Q&A and Discussion<br/>
                                Moderator: Edward Ismawan Chamdani - STARVENTURE Chairman</td>
                            </tr>
                            <tr>
                                <td>10.45-11.00</td>
                                <td>Coffee Break</td>
                            </tr>
                            <tr>
                                <td>11.00-12.00</td>
                                <td><b>Panel Discussion Aviation</b></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>The Expert :<br/>
                                1. Lisa Liu (Ms)- General Manager of BEIJING FORTUNE TECHNOLOGY CO. LTD (confirmed)<br/>
                                2. Razali Ilham - CEO & CO-founder ASLI (confirmed)<br/>
                                3. Ridlo Akbar<br/>
                                4. Raihan - Deputy head of the Asia-Oceania Division for the Overseas Indonesia Alumni Association (OIAA) 2023/2027<br/>
                                Q&A and Discussion Panel Moderator : Bambang Budi Suwarso (KADIN)</td>
                            </tr>
                            <tr>
                                <td>12.00-12.30</td>
                                <td>Sign MOU & Photo</td>
                            </tr>
                            <tr>
                                <td>12.30-13.00</td>
                                <td>MC: Lunch - Entertainment - Closing</td>
                            </tr>
                            <tr>
                                <td>13.00</td>
                                <td>Closing</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div>
                <div class="program-title bg-red">
                    <h1>PROGRAMS</h1>
                </div>
                <div class="program-section">
                    <img src={require('./assets/General BG.png')} alt="bg-2" class="bg-img"/>
                    <div class="mt-30" id="keynotespeakers">
                        <h2>KEY NOTE SPEAKERS</h2>
                        <div class="program-grid">
                            <div class="d-flex flex-center">
                                <div class="pfp-text-md">
                                    <a href="/program/cv"><img src={require('./assets/Gunawan Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                    <h5>Gunawan Eko Movianto, SE., MM</h5> (Agriculture and Food Directorate)<br />(Ministry of Home Affairs of the Republic of Indonesia)
                                </div>
                            </div>
                            <div class="d-flex flex-center">
                                <div class="pfp-text-md">
                                <a href="/program/cv"><img src={require('./assets/John Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                    <h5>Dr. John Xing</h5>(General Secretary of China BRICooperation Committee)
                                </div>
                            </div>
                        </div>
                        <div class="program-grid">
                            <div class="">
                                <h3>Master of Ceremony</h3>
                                <div class="program-grid">
                                    <div class="program-flex-right">
                                        <div class="pfp-text-sm">
                                            <a href="/program/cv"><img src={require('./assets/Frans Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                            Frans Nickolas
                                        </div>
                                    </div>
                                    <div class="program-flex-left">
                                        <div class="pfp-text-sm">
                                        <a href="/program/cv"><img src={require('./assets/Yohana Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                            Yohan Elizabeth Hardjadinata
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="no-margin-mobile">
                                <h3>Moderators</h3>
                                <div class="program-grid">
                                    <div class="program-flex-right">
                                        <div class="pfp-text-sm">
                                        <a href="/program/cv"><img src={require('./assets/Edward Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                            Edward Ismawan Chamdani<br/><span class="fs-12">{'(Moderator for Agriculture)'}</span>
                                        </div>
                                    </div>
                                    <div class="program-flex-left">
                                        <div class="pfp-text-sm">
                                        <a href="/program/cv"><img src={require('./assets/Bambang Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                            Bambang Budi Suwarso<br/><span class="fs-12">{'(Moderator for Aviation)'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="program-title bg-green">
                    <h1>AGRICULTURE</h1>
                </div>
                <div class="program-section">
                    <img src={require('./assets/Agriculture BG.png')} alt="bg-2" class="bg-img"/>
                    <div class="ml-80 mr-80 mt-30">
                        <h2 class="mb-10">PANEL DISCUSSIONS</h2>
                        <h4>“Challenges and Opportunities in the Belt and Road Initiative for Agriculture”
                        <br/>“Exploring the Impact of the BRI on Agriculture Development in Indonesia”</h4>
                    </div>
                    <div class="program-grid">
                        <div class="d-flex flex-center">
                            <div class="pfp-text-md">
                                <a href="/program/cv"><img src={require('./assets/Dodik Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                <h5>Prof. Dr. Dodik Satria S.P., M.Si.</h5>
                                {`(Professor of Forest Policy and Economics)
(Dean of Graduate School at
IPB University Bogor) `}
                            </div>
                        </div>
                        <div class="d-flex flex-center">
                            <div class="pfp-text-md">
                            <a href="/program/cv"><img src={require('./assets/Didiek Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                <h5>Prof.(R). Ir. H. Didiek Hadjar Goenadi, M.Sc.,Ph.D.,IPU.,INV</h5>
                                (Chairman of Indonesian Inventor Association)
                            </div>
                        </div>
                    </div>
                    <div class="program-grid">
                        <div class="d-flex flex-center">
                            <div class="pfp-text-md">
                            <a href="/program/cv"><img src={require('./assets/Lisa Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                <h5>Lisa Liu</h5>
                                {'(General Manager of BEIJING FORTUNE TECHNOLOGY CO. LTD)'}
                            </div>
                        </div>
                        <div class="d-flex flex-center">
                            <div class="pfp-text-md">
                            <a href="/program/cv"><img src={require('./assets/Pieter Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                <h5>Pieter Tangka</h5>
                                <h5>SP.,SE.,MSc.,PhD.</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="program-title bg-blue">
                    <h1>AVIATION</h1>
                </div>
                <div class="program-section mb-50">
                    <img src={require('./assets/Aviation BG.png')} alt="bg-2" class="bg-img"/>
                    <div class="ml-80 mr-80 mt-30">
                        <h2 class="mb-10">PANEL DISCUSSIONS</h2>
                        <h4>“Challenges and Opportunities in the Belt and Road Initiative for Aviation”
                        <br/>“Exploring the Impact of the BRI on Aviation Development in Indonesia”</h4>
                    </div>
                    <div class="program-grid">
                        <div class="d-flex flex-center">
                            <div class="pfp-text-md">
                            <a href="/program/cv"><img src={require('./assets/Lisa Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                <h5>Lisa Liu</h5>
                                {'(General Manager of BEIJING FORTUNE TECHNOLOGY CO. LTD)'}
                            </div>
                        </div>
                        <div class="d-flex flex-center">
                            <div class="pfp-text-md">
                            <a href="/program/cv"><img src={require('./assets/Raihan Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                <h5>Muhammad Raihan Ronodipuro</h5>
                                {'Deputy head of the Asia-Oceania Division for the Overseas Indonesia Alumni Association (OIAA) 2023/2027'}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-40 d-flex flex-center">
                        <div class="d-flex flex-center">
                            <div class="pfp-text-md">
                            <a href="/program/cv"><img src={require('./assets/Razali Circle.png')} alt="speakers" class="speakers-pfp"/></a><br/>
                                <h5>Razali Ilham</h5>
                                {'(CEO & CO-founder ASLI)'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgramPage;