import Footer from './components/Footer';
import NavBar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './HomePage';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import ProgramPage from './ProgramPage';
import Review from './Review';
import ContactPage from './ContactPage';
import CurricululmVitae from './CurriculumVitae';

function App() {
  return (
    <div className='App'>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/program" element={<ProgramPage />} />
          <Route path="/program/cv" element={<CurricululmVitae />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="register" element={<Review />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
