import React from 'react';
import './styles/review.scss';

function Review(props) {
    return (
        <div class="review-section">
            <div class="img">
                <img src={require('./assets/asian-businessmen-businesswomen-meeting-brainstorming-ideas-about-creative-web-design-planning-application-developing-template-layout-mobile-phone-project-working-together-small-office.jpg')} alt="rice field" />
            </div>
            <div class="d-flex flex-center-x">
                <div class="desktop-md mt-40 mb-80">
                    <h1>REGISTER NOW</h1>
                    <div class="orange"/>
                    <p>
                    Join us now to embark on a journey of knowledge and collaboration with the Belt & Road Initiative Seminar! Seize the opportunity to be part of a global conversation shaping the future of international connectivity.
                    </p>
                    <p>
                    Registration fee: <b>FREE</b><br/>
                    *Limited slots: 100 seats
                    </p>
                    <h5 class="mt-70">How to register?</h5>
                    <div class="orange"/>
                    <p>1. Click <a href="https://jsjb81ue03j.typeform.com/to/zlpiKduj" target="_blank" rel='noreferrer'><i>HERE</i></a> to register<br />
                    2. Fill all the necessary forms to register into the event<br />
                    3. Only select candidate will be accepted and approved to join the event</p>
                </div>
            </div>
        </div>
    );
}

export default Review;