import React from 'react';
import './styles/review.scss';

function ContactPage(props) {
    return (
        <section class="contact">
            <div class="d-flex flex-center-x">
                <div class="desktop-md mt-40 mb-80">
                    <h1>CONTACT US</h1>
                    <div class="orange"/>
                    <p>
                    For inquiries or any kind of information, contact us at admin@bri-id.events or text us on WhatsApp (082322882438).
                    </p>
                    <div class="review-grid mt-60">
                        <div class="center-y">
                            <img src={require('./assets/Greenwillow@4x.png')} alt="greenwillow" />
                        </div>
                        <div class="">
                            <p>Founded in 2018, Greenwillow Capital Management is a fund and asset management company with a Capital Markets Services License from the Monetary Authority of Singapore (MAS).
                            </p>
                            <p>With deep investment experience and a strong network of relationships in the region’s local markets, Greenwillow’s core focus is on real estate investments in the fast-growing emerging markets of China, India and Southeast Asia, as well as venture capital in early-stage technology companies across Southeast Asia.
                            </p>
                        </div>
                    </div>
                    <div class="review-grid mt-60 mb-60">
                        <div class="center-y">
                        <img src={require('./assets/Starventure@4x.png')} alt="starventure" />
                        </div>
                        <div class="">
                            <p>Starventure is a venture builder that focuses on systematically creating and launching new businesses or startups. Rather than just investing in existing startups, we proactively generate our own business ideas, validate them, and then build and scale those ideas into fully-fledged companies.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactPage;