import React from 'react';
import './styles/cv.scss';

const speakerslist = [
    {
        title:'Dr. John Xing',
        subtitle:'(General Secretary of China BRI Cooperation Committee)',
        text: 'Dr. Xing contributes himself in bridging and connecting China with the world in respect of enhancing cultural understanding and promoting economic cooperation in a long term. He has over 20 years of experience in the facilitation of technological, economic, and cultural cooperation and exchange between government entities, SOE, private companies, and associations of China, Southeast Asian nations, European, African, South American countries, etc. He is an exemplary practitioner of China’s Belt and Road Initiative during the past 10 years, participating and promoting bilateral and multilateral cooperation in a wide range of social entities and economic sectors in Asia and Europe.',
        img_src: './assets/John Circle.png'
    },
    {
        title:'Ms. Lisa Liu',
        subtitle:'(CEO and Shareholder of Beijing Fortune Technology Co., Ltd)',
        text: 'Ms. Liu is engaged in the investment in high-tech Industries, financial investment in Pre-IPO project and asset management for SOE and private companies in China (Hong Kong and Shenzhen) for about 30 years. She is focused on high-tech project incubation, business planning, international technology cooperation and international trade business. She supports leading aviation enterprises of China in promoting general aviation aircraft to Asian markets, as well as introduces digital agriculture technology to Indonesian partners to help farms increase productivity, enhance quality control and reduce loss in agricultural production.',
        img_src: './assets/Lisa Circle.png'
    },
    {
        title:'Muhammad Raihan Ronodipuro',
        subtitle:`(Special Aide at the House of Representatives (DPR RI))
        (Deputy head of the Asia-Oceania Division for the Overseas Indonesia Alumni Association (OIAA) 2023/2027))`,
        text:`Raihan Ronodipuro holds a Master of Public Administration degree from the School of Public Policy & Management at Tsinghua University, China. His academic journey was propelled by the esteemed Chinese MOFCOM Scholarship, leading him to successfully attain a Master of Law in International Relations from the School of International and Public Affairs at Jilin University, China. Raihan has also served as an Associate Researcher in the Department of Politics and Security at the Center for Indonesia-China Studies (CICS). During that time, he played a pivotal role as a member of the International Relations Commission within the Directorate of Research and Studies for the Overseas Indonesian Students' Association Alliance (OISAA) for the term 2022/2023. Currently, he serves as the Deputy Head of the Asia-Oceania Division for the Overseas Indonesia Alumni Association (OIAA).`,
        img_src:'./assets/Raihan Circle.png'
    },
    {
        title:'Edward Ismawan Chamdani',
        subtitle:'(Co-Founder & Managing Partner at Ideosource VC)',
        text:'Edward Ismawan Chamdani is the Managing Partner of Ideosource VC, a venture capital and private equity firm in Indonesia along with several other firms he initiated. He has a wealth of experience in venture capital, private equity, corporate finance, and other areas of finance. With his expertise, Edward has successfully invested and supported many companies across the archipelago such as the early backer of eFishery, Stockbit, Bibit, Andalin, GoCement, and many others. He has been a key figure in the development of Indonesia’s startup and venture capital ecosystem, helping to create an environment of innovation and opportunities',
        img_src:'./assets/Edward Circle.png'
    },
    {
        title:'Bambang B. Suwarso, AK.MBT.CA',
        subtitle:'(Managing Director, KIB Consulting)',
        text:`<p>Bambang B Suwarso is Managing Director of KIB Consulting, a consulting firm which
provide its services in business, finance, accounting, and tax advisory. He is also Commissioner of PT
Bisnis Digital Optima, a Securities Crowdfunding_ Fintech company, PT Semesta Usaha
Maju, an agriculture company, CV Kiat Indah Bersama_an accounting service company. As
an entrepreneur, he used to have his own business in F&B, property, general insurance, and
steel.</p>
<p>Bambang B Suwarso started his career in the mid-1980s, at BPKP (Badan Pengawas
Keuangan dan Pembangunan_Financial and Development Oversight Body) as state
auditor. He used to be a lecturer for accounting, audit, and managerial finance subject at
several universities in Jakarta such as STAN, PERBANAS, ATMAJAYA University and STIE YAI.
He also used to work for Ministry of Finance and the Central Bank.</p>
<p>He holds master’s degree in business and taxation from the University of Southern
California (USC), Los Angeles-USA and graduated from the State College of Accountancy
(STAN) as he also has its Diploma from Judge Business Scholl, Cambridge University-UK. He
has involved in law-making process such as Law no. 10/1998 Banking Law, Law No.11/2016 Tax
amnesty law and Law No.11 year 2020 Omnibus Law . He contributes his knowledge as
speaker for national and international seminar/forum on finance, tax, insurance,
entrepreneurship and investment.</p>
<p>Nowadays He has also served as Deputy head of Asia Pacific Permanent Committee,
Chairman of MSG (Melanesian Spearhead Group), ASEAN Coordinator of KADIN (Indonesian
Chamber of commerce and Industry) and member of ASEAN BAC. In addition, he also
become founder of Board of Supervisor LKPK (Lembaga Kajian Pajak dan Kepabeanan Tax
and custom study institutions) and Supervisory Board at ALUDI (Asoasiasi Layanan Urun
Dana Indonesia).</p>`,
        img_src:'Bambang Circle.png'
    },
    {
        title:'Pieter Tangka SP.,SE.,MSc.,PhaD.',
        subtitle:'',
        text:`<ul>
        <li>Bachelor s degree in Agricultural Agronomy in 1994 from the Faculty of Agriculture,
        Gadjah Mada University, Yogyakarta</li>
        <li>Bachelor s degree in Management in 1995 from the Faculty of Economics, National
        Development University (UPN-Veteran), Yogyakarta</li>
        <li>Master of Science (MSc) in Fertilizer & Biochemical Engineering in 1998 from the Robert
        H. Smith, Agriculture, and Environmental Faculty, University of Rehoboth (Jerusalem),
        Israel</li>
        <li>Doctor of Philosophy (PhD) in Food Industry Management and Policy in 2001 from
        Technion - Israel Institute of Technology-Haifa, Israel.</li>
    </ul>
    Additionally, he has completed short courses (6 months-1 year), including:
    <ul>
        <li>Drip Irrigation Technique Course (Netafim - 1997)</li>
        <li>Fertigation System Course (Netafim - 1998) </li>
        <li>Urban Farming on Kibbutz (Haifa Group - 2000) </li>
        <li>Nano Fertilizer Bio Technology for Green Ecological Design (Keren Kayemet LeYisrael -
        Jewish National Fund, 1999)</li>
    </ul>
    Professional Experience and Contributions includeG
    <ul>
        <li>2002-2004, Worked at the Nano Technology Fertilizer Factory Nongfeng Inc. - Tongren
        (Guizhou - China) as a Formulator Consultant and later became the Area Manager for the
        ASEAN region</li>
        <li>2004-2008, Worked for the High-Tech Organic Fertilizer Producer Sholter Inc. (Austin,
        Texas, US) as a Consultant for New Business Project Development, later as a Consultant
        for the South American region</li>
        <li>2008-2012, Worked as a Senior Food Policy Advisor for East and Central Africa at the
        World Food Programme (Libreville, Gabon, East Africa).</li>
    </ul>
    In July 2012, returned to Indonesia and worked at:
    <ul>
        <li>PT. Bosowa Agro, Makassar, South Sulawesi as a Consultant</li>
        <li>March 2013, Worked as a Special Staff to the Governor of North Sulawesi</li>
        <li>April 2013, Became the Chief Executive Officer of PT Gunung Mas Agro Lestari
        (Development of Chili, Onion, Fruits, etc.) in North Sulawesi, South Sulawesi, East Java,
        and Bali</li>
        <li>2018, Worked as the Managing Director at PT Sarotama DK, specifically for the
        Development of Corn, Rice, and Sorghum in West Java, Central Java, and NTB (Bima and
        Dompu)</li>
        <li>2020, Worked as the Director at PT Niaga Global Mandiri for North Sumatra, also as a
        Researcher/Academician for the Planning of the Ministry of Agriculture s Food Estate
        Program</li>
        <li>Additional roles as an Advisor for Programs and Policies related to Food and Agriculture,
        Rural and Cooperative at Bappenas and the Ministry of Agriculture (unofficially)</li>
        <li>Speaker at Seminars and Guest Lecturer and Trainer in Agribusiness.</li>
    </ul>
    Organizations involved in includeG
    <ul>
        <li>HKTI (Prabowo s version)</li>
        <li>Deputy Chairman of the Food Task Force FKPP7</li>
        <li>Member of the Commodity Board (Coffee, Corn, Chili, Onion)</li>
        <li>Founder of CII (Carbon Initiative Indonesia)</li>
        <li>Expert Council at the Organic Farming Community (MAPORINA)</li>
    </ul>`,
        img_src:'./assets/Pieter Circle'
    },
    {
        title:'Captain Razali Ilham',
        subtitle:'(Founder and CEO PT. ASLI SATU INDONESIA)',
        text:`<p>Captain Ilham brings 27 years of aviation experience. Starting his career in Indonesia as a pilot in the 1994, he has held various experience in management positions as Director, Pilot and General Manager.</p>
        <p>Since 2019, he has been the CEO and of PT ASLI Satu Indonesia, a freight forwarder based in Jakarta, establishing a trucking service and a shipping cargo forwarding service  also Multi modal system for logistic connectivity to in tier 2 and tier 3 cities.</p>
        <p>Prior to this he was Director of Aviastar Mandiri, a regional airline in Indonesia from 2017 to 2019 where he restructured the airline’s debt and resumed operations using a fleet of Bae146-200 and DHC6-30, turning it into a stable and profitable venture. He later passed the airline to previous shareholders before joining ASLI.</p>
        <p>In 2015 he formed Skycab Aviation, an air charter company, where he also acted as Director.
        Skycab was involved in arranging and broking flights in commercial and private jets, plus
        helicopters. Prior to that, his first management role was at PT Survai Udara Penas, a
        government-owned organization where he was the General Manager for an Aerial Survey
        business, operating aircraft with large format camera for government projects.</p>
        <p>As a pilot, Captain Razali has worked in many airlines across the world flying B737s, A320s
        and A300s. He worked with Merpati airlines, Qatar Airways, Air Hongkong, Hongkong
        Airlines and the last served as a B737-800 Captain for Sriwijaya Air in Jakarta until 2022</p>`,
        img_src:'./assets/Razali Circle.png'
    },
    {
        title:'Dodik Ridho Nurrochmat',
        subtitle:`(Professor of Forest Policy and Economics)
        (Dean of Graduate School at
        IPB University Bogor)`,
        text:`Dodik Ridho Nurrochmat is a Professor of Forest Policy and Economics and Dean of Graduate School at IPB University Bogor – Indonesia. He graduated Bachelor at IPB, and completed his Master's and Ph.D. at Goettingen University Germany (summa cum laude). He previously served as Vice-Rector for International Affairs, Collaboration, and Alumni Relations (2021-2023), Vice-Rector for Collaboration & Information Systems (2018-2021) and subsequently also the Chairman of the Forwarek Kerjasama, a forum of the Indonesian Vice-Rectors for Collaboration (2019-2022), Director of Strategic Studies Studies & Agriculture Policy (2013-2018), and Director of Career Development and Alumni Affairs IPB (2008-2013).
        In the scientific community, he serves as the Chairman of the Scientific Advisory Board of the Indonesian Forestry Scholars Association (PERSAKI) and alternate International Council of the International Union of Forest Research Organizations (IUFRO), advisory editorial board in some reputable international journals, such as the Journal of Forest Policy (Elsevier) and Economics, Journal of Forest and Society, Journal of Tropical Forest Management (JMHT), and several others. He visited some prominent universities such as MIT, Harvard, Stanford, and NUS to initiate collaboration and also serves as a guest/visiting professor/co-supervisor/examiner at the University of Malaya, Roskilde University, Goettingen University, Oxford University, Leeds University, Michigan University, Adelaide University, and some other universities in Asia, Europe, and the USA. He is actively involved in the policy dialogues in several regional (ASEAN), national, and local policy-making processes. He has conducted scientific research and consultancies, mainly elaborating on socio-economics and policy aspects of carbon and climate change, forest management and environment, including various issues of land-use change, nature conservation, deforestation, sustainable forest management, timber, and non-timber forest products and marketing, green fiscal policy, sustainable development, as well as emission reduction and climate change in cooperation with national institutions and international agencies/consultants, such as World Bank, ADB, UNDP, UNREDD, GGGI, USAID, GIZ, KOICA, ITTO, OPM, etc.`
    },
    {
        title:'Prof.(R). Ir. H. Didiek Hadjar Goenadi, M.Sc.,Ph.D.,IPU.,INV',
        subtitle:'(Chairman of Indonesian Inventor Association)',
        text:`<li>S1- Bogor Agricultural University (IPB) (1981)</li>
        <li>S2- University of Georgia, Athens, GA, USA (1989)</li>
        <li>Short Course of Angkatan (KSA) IX Lemhannas (2001)</li>
        <li>Expert Staff of the Minister of State for Research and Technology in the field of Economics & Technology (2000-2003)</li>
            <li>Executive Director of the Indonesian Palm Oil Research Institute (LRPI) (2002-2007)</li>
                <li>Member of the Investment Committee at the Investment Coordinating Board (BKPM) in the Agribusiness Sector (2007-2011)</li>
                    <li>Chief Executive of the Indonesian Palm Oil Entrepreneurs Association (GAPKI) (2010)</li>
                        <li>Member of the Board of Commissioners of PT Rajawali Nusantara Indonesia (2011)</li>
                            <li>CEO of PT Palm Research Nusantara (RPN) (2011-2015)</li>
                                <li>Member of the Research and Development Committee at the Indonesian Palm Oil Fund Management Agency (BPDPKS) (2016-Ongoing)</li>
                                    <li>Research Expert in the field of State-Owned Enterprises Fertilizer (PT Pupuk Indonesia Holding) (2007-2020)</li>
                                            <li>Chairman of the Indonesian Soil Science Association (HITI) (1999-2003)</li>
                                                <li>Advisory Committee member of the International Biochar Initiatives (IBI) (2014)</li>
                                                    <li>Chairman of the Supervisory Board of the Indonesian Researchers Association (PPI) (2022-Ongoing)</li>
                                                    <li>    Chairman of the Indonesian Inventors Association (AII) (2008-Ongoing)</li>
                                                        <li> 11 inventions (biofertilizer, biodecomposer, and soil bioameliorant) (1998-2021)</li>
                                                            <li>10 books (inventions, fertilizers/fertilization, biodecomposition, soil improvement)(1990-2021)</li>
                                                            <li>Phi Kappa Phi (Summa Cum Laude) (1986)</li>
                                                                <li>Satyalencana Wirakarya (1998)</li>
                                                                    <li>Gold Medal Award from the Cyber International Genius Inventor Fair (CIGIF), Korea: Bio-P and Bio-K (2011)</li>
                                                                        <li> Indonesian Record Museum (MURI) for the Biofertilizer Invention (2018)</li>
                                                                            <li>Gold Medal Award from the Cyber International Genius Inventor Fair (CIGIF), Korea: BioSilicic Acid (2020)</li>
                                                                                <li>Gold Medal Award (BioSilAc) from the Toronto International Society of Innovation and Advanced Skills (TISIAS) Canada: BioSilicic Acid (2021)</li>
                                                                                    <li>Committee of Expert, International Rubber Research Development Board (IRRDB) (2023)</li>`
    },
    {
        title:'Alan Lai',
        subtitle: '(CEO of ProfilePrint)',
        text:`Alan is the founder & CEO of ProfilePrint, and the inventor of its patented A.I. digital food
        fingerprint technology. Before starting ProfilePrint, he was the Assistant CEO of IHDpay
        Group, a Hong Kong investment firm focusing on digital payment KYC in China. Prior, his
        professional experience with CapitaLand includes heading M&A / investment in the United
        Kingdom, General Manager South China Ascott and Assistant to Group CEO President
        CapitaLand. He is concurrently a reserve deputy Squadron Commander at the Singapore Air
        Force with previous key appointments including Flight Commander and Staff Officer at
        Chief of Air Force Office. He was a PhD candidate in Operational Research at INSEAD,
        completed a post-graduate management program at Tsinghua University and graduated
        with French Singapore Double Engineering degrees (1st Class) from Ecole Centrale Paris
        and National University of Singapore under a government scholarship.`
    }
]

function CurriculumVitae(props) {
    return (
        <div class="d-flex flex-center-x">
            <div class="desktop-md cv mt-40">
                <div class="d-flex mb-20">
                    <h1>Speaker's CV</h1>
                </div>
                <div class="orange mb-50"/>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[0].title} src={require('./assets/John Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[0].title}</h4>
                        {speakerslist[0].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <div class="orange mt-10 mb-10 vh-resp"/>
                        <p>{speakerslist[0].text}</p>
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[1].title} src={require('./assets/Lisa Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[1].title}</h4>
                        {speakerslist[1].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <p>{speakerslist[1].text}</p>
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[2].title} src={require('./assets/Raihan Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[2].title}</h4>
                        {speakerslist[2].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <p>{speakerslist[2].text}</p>
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[3].title} src={require('./assets/Edward Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[3].title}</h4>
                        {speakerslist[3].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <p>{speakerslist[3].text}</p>
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[4].title} src={require('./assets/Bambang Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[4].title}</h4>
                        {speakerslist[4].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <div>
                            <p>
                                Bambang B Suwarso is Managing Director of KIB Consulting, a consulting firm which provide its services in business, finance, accounting, and tax advisory. He is also Commissioner of PT
                                Bisnis Digital Optima, a Securities Crowdfunding_ Fintech company, PT Semesta Usaha
                                Maju, an agriculture company, CV Kiat Indah Bersama_an accounting service company. As
                                an entrepreneur, he used to have his own business in F&B, property, general insurance, and
                                steel.
                            </p>
                            <br/>
                            <p>
                                Bambang B Suwarso started his career in the mid-1980s, at BPKP (Badan Pengawas
                                Keuangan dan Pembangunan_Financial and Development Oversight Body) as state
                                auditor. He used to be a lecturer for accounting, audit, and managerial finance subject at
                                several universities in Jakarta such as STAN, PERBANAS, ATMAJAYA University and STIE YAI.
                                He also used to work for Ministry of Finance and the Central Bank.
                            </p>
                            <br/>
                            <p>
                                He holds master’s degree in business and taxation from the University of Southern
                                California (USC), Los Angeles-USA and graduated from the State College of Accountancy
                                (STAN) as he also has its Diploma from Judge Business Scholl, Cambridge University-UK. He
                                has involved in law-making process such as Law no. 10/1998 Banking Law, Law No.11/2016 Tax
                                amnesty law and Law No.11 year 2020 Omnibus Law . He contributes his knowledge as
                                speaker for national and international seminar/forum on finance, tax, insurance,
                                entrepreneurship and investment.
                            </p>
                            <br/>
                            <p>
                                Nowadays He has also served as Deputy head of Asia Pacific Permanent Committee,
                                Chairman of MSG (Melanesian Spearhead Group), ASEAN Coordinator of KADIN (Indonesian
                                Chamber of commerce and Industry) and member of ASEAN BAC. In addition, he also
                                become founder of Board of Supervisor LKPK (Lembaga Kajian Pajak dan Kepabeanan Tax
                                and custom study institutions) and Supervisory Board at ALUDI (Asoasiasi Layanan Urun
                                Dana Indonesia).
                            </p>
                        </div>
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[5].title} src={require('./assets/Pieter Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[5].title}</h4>
                        {speakerslist[5].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <div>
                            <p>
                            Piet Gregorius Tangka, also known as Pieter Tangka, is an Academician, Practitioner, and
                            Consultant in the fields of Agriculture, Agribusiness, Agroindustry, and Agrotechnology, with
                            the following educational background:
                            </p>
                            <ul>
                                <li>Bachelor s degree in Agricultural Agronomy in 1994 from the Faculty of Agriculture,
                                Gadjah Mada University, Yogyakarta</li>
                                <li>Bachelor s degree in Management in 1995 from the Faculty of Economics, National
                                Development University (UPN-Veteran), Yogyakarta</li>
                                <li>Master of Science (MSc) in Fertilizer & Biochemical Engineering in 1998 from the Robert
                                H. Smith, Agriculture, and Environmental Faculty, University of Rehoboth (Jerusalem),
                                Israel</li>
                                <li>Doctor of Philosophy (PhD) in Food Industry Management and Policy in 2001 from
                                Technion - Israel Institute of Technology-Haifa, Israel.</li>
                            </ul>
                            <p>
                                Additionally, he has completed short courses (6 months-1 year), including:
                            </p>
                            <ul>
                                <li>Drip Irrigation Technique Course (Netafim - 1997)</li>
                                <li>Fertigation System Course (Netafim - 1998) </li>
                                <li>Urban Farming on Kibbutz (Haifa Group - 2000) </li>
                                <li>Nano Fertilizer Bio Technology for Green Ecological Design (Keren Kayemet LeYisrael -
                                Jewish National Fund, 1999)</li>
                            </ul>
                            <p>Professional Experience and Contributions include:
                            </p>
                            <ul>
                                <li>2002-2004, Worked at the Nano Technology Fertilizer Factory Nongfeng Inc. - Tongren
                                (Guizhou - China) as a Formulator Consultant and later became the Area Manager for the
                                ASEAN region</li>
                                <li>2004-2008, Worked for the High-Tech Organic Fertilizer Producer Sholter Inc. (Austin,
                                Texas, US) as a Consultant for New Business Project Development, later as a Consultant
                                for the South American region</li>
                                <li>2008-2012, Worked as a Senior Food Policy Advisor for East and Central Africa at the
                                World Food Programme (Libreville, Gabon, East Africa).</li>
                            </ul>
                            <p>In July 2012, returned to Indonesia and worked at:</p>
                            <ul>
                                <li>PT. Bosowa Agro, Makassar, South Sulawesi as a Consultant</li>
                                <li>March 2013, Worked as a Special Staff to the Governor of North Sulawesi</li>
                                <li>April 2013, Became the Chief Executive Officer of PT Gunung Mas Agro Lestari
                                (Development of Chili, Onion, Fruits, etc.) in North Sulawesi, South Sulawesi, East Java,
                                and Bali</li>
                                <li>2018, Worked as the Managing Director at PT Sarotama DK, specifically for the
                                Development of Corn, Rice, and Sorghum in West Java, Central Java, and NTB (Bima and
                                Dompu)</li>
                                <li>2020, Worked as the Director at PT Niaga Global Mandiri for North Sumatra, also as a
                                Researcher/Academician for the Planning of the Ministry of Agriculture s Food Estate
                                Program</li>
                                <li>Additional roles as an Advisor for Programs and Policies related to Food and Agriculture,
                                Rural and Cooperative at Bappenas and the Ministry of Agriculture (unofficially)</li>
                                <li>Speaker at Seminars and Guest Lecturer and Trainer in Agribusiness.</li>
                            </ul>
                            <p>Organizations involved in include:</p>
                            <ul>
                                <li>HKTI (Prabowo s version)</li>
                                <li>Deputy Chairman of the Food Task Force FKPP7</li>
                                <li>Member of the Commodity Board (Coffee, Corn, Chili, Onion)</li>
                                <li>Founder of CII (Carbon Initiative Indonesia)</li>
                                <li>Expert Council at the Organic Farming Community (MAPORINA)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[6].title} src={require('./assets/Razali Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[6].title}</h4>
                        {speakerslist[6].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <div>
                            <p>Captain Ilham brings 27 years of aviation experience. Starting his career in Indonesia as a pilot in the 1994, he has held various experience in management positions as Director, Pilot and General Manager.</p>
                            <br/>
                            <p>Since 2019, he has been the CEO and of PT ASLI Satu Indonesia, a freight forwarder based in Jakarta, establishing a trucking service and a shipping cargo forwarding service  also Multi modal system for logistic connectivity to in tier 2 and tier 3 cities.</p>
                            <br/>
                            <p>Prior to this he was Director of Aviastar Mandiri, a regional airline in Indonesia from 2017 to 2019 where he restructured the airline’s debt and resumed operations using a fleet of Bae146-200 and DHC6-30, turning it into a stable and profitable venture. He later passed the airline to previous shareholders before joining ASLI.</p>
                            <br/>
                            <p>In 2015 he formed Skycab Aviation, an air charter company, where he also acted as Director.
                            Skycab was involved in arranging and broking flights in commercial and private jets, plus
                            helicopters. Prior to that, his first management role was at PT Survai Udara Penas, a
                            government-owned organization where he was the General Manager for an Aerial Survey
                            business, operating aircraft with large format camera for government projects.</p>
                            <br/>
                            <p>As a pilot, Captain Razali has worked in many airlines across the world flying B737s, A320s
                            and A300s. He worked with Merpati airlines, Qatar Airways, Air Hongkong, Hongkong
                            Airlines and the last served as a B737-800 Captain for Sriwijaya Air in Jakarta until 2022</p>
                        </div>
                    </div>                     
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[7].title} src={require('./assets/Dodik Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[7].title}</h4>
                        {speakerslist[7].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <div><p>
                        Dodik Ridho Nurrochmat is a Professor of Forest Policy and Economics and Dean of Graduate School at IPB University Bogor – Indonesia. He graduated Bachelor at IPB, and completed his Master's and Ph.D. at Goettingen University Germany (summa cum laude). He previously served as Vice-Rector for International Affairs, Collaboration, and Alumni Relations (2021-2023), Vice-Rector for Collaboration & Information Systems (2018-2021) and subsequently also the Chairman of the Forwarek Kerjasama, a forum of the Indonesian Vice-Rectors for Collaboration (2019-2022), Director of Strategic Studies Studies & Agriculture Policy (2013-2018), and Director of Career Development and Alumni Affairs IPB (2008-2013).
In the scientific community, he serves as the Chairman of the Scientific Advisory Board of the Indonesian Forestry Scholars Association (PERSAKI) and alternate International Council of the International Union of Forest Research Organizations (IUFRO), advisory editorial board in some reputable international journals, such as the Journal of Forest Policy (Elsevier) and Economics, Journal of Forest and Society, Journal of Tropical Forest Management (JMHT), and several others. He visited some prominent universities such as MIT, Harvard, Stanford, and NUS to initiate collaboration and also serves as a guest/visiting professor/co-supervisor/examiner at the University of Malaya, Roskilde University, Goettingen University, Oxford University, Leeds University, Michigan University, Adelaide University, and some other universities in Asia, Europe, and the USA. He is actively involved in the policy dialogues in several regional (ASEAN), national, and local policy-making processes. He has conducted scientific research and consultancies, mainly elaborating on socio-economics and policy aspects of carbon and climate change, forest management and environment, including various issues of land-use change, nature conservation, deforestation, sustainable forest management, timber, and non-timber forest products and marketing, green fiscal policy, sustainable development, as well as emission reduction and climate change in cooperation with national institutions and international agencies/consultants, such as World Bank, ADB, UNDP, UNREDD, GGGI, USAID, GIZ, KOICA, ITTO, OPM, etc.
                        </p>
                        </div>              
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left mt-20">
                        <img alt={speakerslist[8].title} src={require('./assets/Didiek Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[8].title}</h4>
                        {speakerslist[8].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <div>
                            <ul>
                                <li>S1- Bogor Agricultural University (IPB) (1981)</li>
                                <li>S2- University of Georgia, Athens, GA, USA (1989)</li>
                                <li>Short Course of Angkatan (KSA) IX Lemhannas (2001)</li>
                                <li>Expert Staff of the Minister of State for Research and Technology in the field of Economics & Technology (2000-2003)</li>
                                <li>Executive Director of the Indonesian Palm Oil Research Institute (LRPI) (2002-2007)</li>
                                <li>Member of the Investment Committee at the Investment Coordinating Board (BKPM) in the Agribusiness Sector (2007-2011)</li>
                                <li>Chief Executive of the Indonesian Palm Oil Entrepreneurs Association (GAPKI) (2010)</li>
                                <li>Member of the Board of Commissioners of PT Rajawali Nusantara Indonesia (2011)</li>
                                <li>CEO of PT Palm Research Nusantara (RPN) (2011-2015)</li>
                                <li>Member of the Research and Development Committee at the Indonesian Palm Oil Fund Management Agency (BPDPKS) (2016-Ongoing)</li>
                                <li>Research Expert in the field of State-Owned Enterprises Fertilizer (PT Pupuk Indonesia Holding) (2007-2020)</li>
                                <li>Chairman of the Indonesian Soil Science Association (HITI) (1999-2003)</li>
                                <li>Advisory Committee member of the International Biochar Initiatives (IBI) (2014)</li>
                                <li>Chairman of the Supervisory Board of the Indonesian Researchers Association (PPI) (2022-Ongoing)</li>
                                <li>Chairman of the Indonesian Inventors Association (AII) (2008-Ongoing)</li>
                                <li>11 inventions (biofertilizer, biodecomposer, and soil bioameliorant) (1998-2021)</li>
                                <li>10 books (inventions, fertilizers/fertilization, biodecomposition, soil improvement)(1990-2021)</li>
                                <li>Phi Kappa Phi (Summa Cum Laude) (1986)</li>
                                <li>Satyalencana Wirakarya (1998)</li>
                                <li>Gold Medal Award from the Cyber International Genius Inventor Fair (CIGIF), Korea: Bio-P and Bio-K (2011)</li>
                                <li> Indonesian Record Museum (MURI) for the Biofertilizer Invention (2018)</li>
                                <li>Gold Medal Award from the Cyber International Genius Inventor Fair (CIGIF), Korea: BioSilicic Acid (2020)</li>
                                <li>Gold Medal Award (BioSilAc) from the Toronto International Society of Innovation and Advanced Skills (TISIAS) Canada: BioSilicic Acid (2021)</li>
                                <li>Committee of Expert, International Rubber Research Development Board (IRRDB) (2023)</li>
                            </ul>
                        </div>  
                    </div>
                </div>
                <div class="cv-grid">
                    <div class="col-left">
                        <img alt={speakerslist[9].title} src={require('./assets/Alan Circle.png')} class="pfp-cv" />
                        <h4>{speakerslist[9].title}</h4>
                        {speakerslist[9].subtitle}
                    </div>
                    <div class="col-right d-flex flex-center">
                        <div class="orange-vl"></div>
                        <p>{speakerslist[9].text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CurriculumVitae;