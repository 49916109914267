import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageCarousel = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  if(setSelectedIndex) {
    
  }

  return (
    <Carousel
      selectedItem={selectedIndex}
      autoPlay={true}
      showThumbs={false}
    >
      <div>
        <img src={require('../assets/Flyer_Web.png')} alt="First scroll" />
      </div>
      <div>
        <img src={require('../assets/new flyer.png')} alt="First scroll" />
      </div>
      <div>
        <img src={require('../assets/Aviation Web.png')} alt="Second scroll" />
      </div>
    </Carousel>
  );
};

export default ImageCarousel;
